<template>
    <div>
        <div id="template-detail-contact" class="relative sm:w-full md:w-full lg:w-full mx-auto self-center">
            <div class="header flex">
                <div class="back flex items-center justify-center mr-auto" @click="back">
                    <feather-icon icon="ArrowLeftIcon"></feather-icon>
                </div>
                <div class="title flex items-center uppercase">Detail email</div>
                <div class="save flex items-center justify-center ml-auto" @click.prevent="openConfirm">
                    <feather-icon icon="TrashIcon"></feather-icon>
                </div>
            </div>
            <div class="body sm:w-full md:w-full lg:w-full mx-auto self-center">
                <div class="head-contact d-flex">
                    <div class="grid">
                        <span class="head-contact-name">{{message.full_name}}</span>
                        <span class="head-contact-email">Email: {{message.email}}</span>
                        <span class="head-contact-email">Phone: {{message.phone}}</span>
                        <span class="head-contact-email">State: {{message.state}}</span>
                    </div>
                    <span class="head-contact-date ml-auto">{{message.created_at}}</span>
                </div>
                <div class="body-contact mt-3">
                    <div class="mb-3">Click here for <a href="javascript:;" @click="popupActive1 = true;">Reply</a>
                        or <a href="javascript:;" @click="popupActive2 = true;">forward</a></div>
                    {{message.message}}
                </div>
            </div>
            <vs-popup classContent="popup-example" title="Reply email" :active.sync="popupActive1">
                <div class="vx-row mb-6">
                    <div class="vx-col w-full">
                        <vs-input class="w-full" type="text" label="Subject" v-model="form.subject"
                            :danger="validation.hasError('form.subject')" val-icon-danger="clear"
                            :danger-text="validation.firstError('form.subject')" />
                    </div>
                </div>
                <div class="vx-row mb-4">
                    <div class="vx-col w-full">
                        <label class="vs-input--label">
                            Message
                        </label>
                        <vs-textarea v-model="form.message" />
                    </div>
                </div>
                <div class="vx-row mb-4">
                    <div class="vx-col w-full">
                        <vs-button class="w-full" icon-pack="feather" icon="icon-send" @click.prevent="sendMessage">
                            Send</vs-button>
                    </div>
                </div>
            </vs-popup>
            <vs-popup classContent="popup-example" title="Forward email" :active.sync="popupActive2">
                <div class="vx-row mb-6">
                    <div class="vx-col w-full">
                        <label class="vs-input--label">
                            Forward email (multiple email separated with comma)
                        </label>
                        <vs-textarea v-model="form.email" />
                    </div>
                </div>
                <div class="vx-row mb-4">
                    <div class="vx-col w-full">
                        <vs-button class="w-full" icon-pack="feather" icon="icon-fast-forward" @click.prevent="forward">
                            Send</vs-button>
                    </div>
                </div>
            </vs-popup>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import SimpleVueValidation from 'simple-vue-validator'
    const Validator = SimpleVueValidation.Validator

    Vue.use(SimpleVueValidation)
    export default {
        data() {
            return {
                popupActive1: false,
                popupActive2: false,
                message: [],
                form: {
                    id: '',
                    subject: '',
                    message: '',
                    email: ''
                }
            }
        },
        validators: {
            'form.subject': {
                cache: true,
                debounce: 500,
                validator: function (value) {
                    return Validator.value(value).required()
                }
            },
        },
        mounted() {
            this.loadData()
        },
        methods: {
            async loadData() {
                await this.getGroupParam();
                await this.getDataMessage().then(resp => {
                    this.message = resp
                })
                await this.actionUpdate()
            },
            back() {
                this.$router.go(-1)
            },
            getGroupParam() {
                try {
                    this.form.id = this.$route.params.id
                } catch {
                    this.$router.push('/')
                }
            },
            getDataMessage() {
                return new Promise((resolve, reject) => {
                    this.$vs.loading()
                    const data = {
                        id: this.form.id
                    }
                    this.$http({
                            url: 'v1/contact/retrieve',
                            method: 'POST',
                            data: data
                        })
                        .then(response => {
                            this.$vs.loading.close()
                            const data = response.data.serve
                            resolve(data)
                        })
                        .catch(error => {
                            this.$vs.loading.close()
                            reject(error)
                        })
                })
            },
            sendMessage() {
                this.$validate().then(result => {
                    if (result) {
                        this.$vs.loading()
                        this.actionSend().then(() => {
                                this.$vs.loading.close()
                                this.$vs.notify({
                                    title: 'Success!',
                                    text: 'Success!',
                                    color: 'success',
                                    position: 'top-center'
                                })
                                this.popupActive1 = false
                                this.form.subject = ""
                                this.form.message = ""
                            })
                            .catch(err => {
                                this.$vs.loading.close()
                                this.$vs.notify({
                                    title: 'Oops!',
                                    text: err.response ? err.response.data.message :
                                        'Something error, ' + err,
                                    color: 'danger',
                                    position: 'top-center'
                                })
                            })
                    }
                })
            },
            actionSend() {
                return new Promise((resolve, reject) => {
                    const data = {
                        email: this.message.email,
                        subject: this.form.subject,
                        message: this.form.message,
                        contact_name: this.message.full_name,
                        contact_message: this.message.message,
                        response: true,
                    }
                    this.$vs.loading()
                    this.$http({
                            url: 'v1/contact/sendmessage',
                            method: 'POST',
                            data: data
                        })
                        .then(response => {
                            this.$vs.loading.close()
                            const data = response.data.serve
                            resolve(data)
                        })
                        .catch(error => {
                            this.$vs.loading.close()
                            reject(error)
                        })
                })
            },
            forward() {
                this.$vs.loading()
                this.actionForward().then(() => {
                        this.$vs.loading.close()
                        this.$vs.notify({
                            title: 'Success!',
                            text: 'Success!',
                            color: 'success',
                            position: 'top-center'
                        })
                        this.popupActive2 = false
                        this.form.email = ""
                    })
                    .catch(err => {
                        this.$vs.loading.close()
                        this.$vs.notify({
                            title: 'Oops!',
                            text: err.response ? err.response.data.message : 'Something error, ' + err,
                            color: 'danger',
                            position: 'top-center'
                        })
                    })
            },
            actionForward() {
                return new Promise((resolve, reject) => {
                    const data = {
                        emails: this.form.email,
                        response: true,
                        forward: true,
                        contact_email: this.message.email,
                        contact_message: this.message.message
                    }
                    this.$vs.loading()
                    this.$http({
                            url: 'v1/contact/sendmessage',
                            method: 'POST',
                            data: data
                        })
                        .then(response => {
                            this.$vs.loading.close()
                            const data = response.data.serve
                            resolve(data)
                        })
                        .catch(error => {
                            this.$vs.loading.close()
                            reject(error)
                        })
                })
            },
            deleteMessage() {
                this.actionDelete().then(() => {
                        this.$vs.notify({
                            title: 'Success!',
                            text: 'Deleted!',
                            color: 'success',
                            position: 'top-center'
                        })
                        window.location.href = "/contact"
                    })
                    .catch(err => {
                        this.$vs.notify({
                            title: 'Oops!',
                            text: err.response ? err.response.data.message : 'Something error, ' + err,
                            color: 'danger',
                            position: 'top-center'
                        })
                        this.$vs.loading.close()
                    })

            },
            actionDelete() {
                return new Promise((resolve, reject) => {
                    const data = {
                        id: this.form.id
                    }
                    this.$vs.loading()
                    this.$http({
                            url: 'v1/contact',
                            method: 'DELETE',
                            data: data
                        })
                        .then(response => {
                            this.$vs.loading.close()
                            const data = response.data.serve
                            resolve(data)
                        })
                        .catch(error => {
                            this.$vs.loading.close()
                            reject(error)
                        })
                })
            },
            actionUpdate() {
                return new Promise((resolve, reject) => {
                    const data = {
                        id: this.form.id
                    }
                    this.$vs.loading()
                    this.$http({
                            url: 'v1/contact',
                            method: 'PUT',
                            data: data
                        })
                        .then(response => {
                            this.$vs.loading.close()
                            const data = response.data.serve
                            resolve(data)
                        })
                        .catch(error => {
                            this.$vs.loading.close()
                            reject(error)
                        })
                })
            },
            openConfirm() {
                this.$vs.dialog({
                    type: 'confirm',
                    color: 'danger',
                    title: `Delete`,
                    text: 'Are you sure?click `delete` to delete data.',
                    accept: this.deleteMessage,
                    acceptText: 'Delete'
                })
            },
        },
    }
</script>
<style lang="scss" scoped>
    @import "@/assets/scss/vuesax/_variables.scss";

    #template-detail-contact {
        min-height: 400px;
        background-color: #ffffff;
        box-shadow: 0 0px 20px 0 rgba(0, 0, 0, .05);
        border-radius: .5rem;
        padding-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;

        .header {
            padding-top: 20px;
            margin-bottom: 40px;

            .help-toggle {
                background: rgba(var(--vs-primary), 1) !important !important;

                span {
                    font-size: 16px;
                    color: rgba(var(--vs-primary), 1) !important !important;
                }
            }

            .back {
                width: 40px;
                height: 40px;
                background: rgba(0, 0, 0, .05);
                border-radius: 20px;
                cursor: pointer;

                .feather-icon {
                    width: 20px;
                    height: 20px;
                }
            }

            .title {
                color: #444444;
                font-weight: 600;
                font-size: 1.3rem;
                line-height: 1;
            }

            .save {
                width: 40px;
                height: 40px;
                background: rgba(var(--vs-primary), 1) !important;
                border-radius: 20px;
                cursor: pointer;

                .feather-icon {
                    color: $white;
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .body {
            .head-contact {
                padding-bottom: 10px;
                border-bottom: 1px solid #eee;

                &.d-flex {
                    display: flex;
                }

                .grid {
                    display: grid;
                }

                .head-contact-name {
                    color: #525252;
                    font-weight: bold;
                    font-size: 17px;
                }

                .head-contact-email {
                    font-size: 12px;
                }
            }

            .body-contact {
                word-break: break-all;

                div {
                    background: whitesmoke;
                    padding: 10px;
                }
            }

            .primary {
                color: $primary;
            }

            .error-msg {
                color: $danger;
                font-size: 11px
            }

            .icons {
                width: 100%;

                li {
                    width: 40px;
                    height: 40px;
                    border-radius: 3px;
                    border: 1px solid transparent;
                    transition: all .5s;
                    cursor: pointer;

                    .feather-icon {
                        width: 20px;
                        height: 20px;
                    }

                    &:hover {
                        background: rgb(255, 255, 255);
                        border: 1px solid rgb(248, 249, 250);
                        box-shadow: 0 0px 10px 0 rgba(0, 0, 0, .05);
                    }

                    &.active {
                        background: rgba(var(--vs-primary), 1) !important;

                        .feather-icon {
                            color: $white;
                        }
                    }
                }
            }
        }
    }
</style>